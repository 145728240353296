body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("@progress/kendo-theme-default/dist/all.css");
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3182ce;
}

.tree-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
}
.tree-scroll:hover {
  overflow-y: auto;
}

/* fixed scroll  bar for lookup annotations */
.tree-scroll-fixed {
  overflow-x: hidden;
  overflow-y: auto;
}
/* .k-item .k-treeview-item {
  z-index: -1;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
